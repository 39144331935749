import { Box } from '@stars-ecom/shared-atoms-ui'
import { BlogCategorySection, BlogHeader } from '@stars-ecom/shared-organisms-ui'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import { Helmet } from 'react-helmet'

import { lengthLimiter } from '../utils/metaTitleFormatter'
import { simplifyGQLResult } from '../utils/strapi'

const Blog = (props) => {
  const { data } = props
  const blog = simplifyGQLResult(get(data, 'strapi.blog'))

  return (
    <Box w="100%">
      <Helmet
        bodyAttributes={{
          class: 'chakra-ui-light'
        }}>
        <meta charSet="utf-8" />
        <title>{lengthLimiter(blog?.metaTitle)}</title>
        <meta name="description" content={blog?.metaDescription} />
      </Helmet>
      <BlogHeader blog={blog} />
      {blog?.blog_categories?.map(({ blog_category }) => {
        return (
          <BlogCategorySection
            key={`blogCategory_${blog_category?.id}`}
            blogCategory={blog_category}
          />
        )
      })}
    </Box>
  )
}

export const query = graphql`
  query blogs($idBlog: ID, $startDate: Strapi_DateTime, $endDate: Strapi_DateTime, $websiteId: ID) {
    strapi {
      blog(id: $idBlog) {
        data {
          id
          attributes {
            title
            metaTitle
            metaDescription
            subTitle
            backgroundImage {
              data {
                id
                attributes {
                  url
                  mime
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        width: 1360
                        height: 386
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }
            blog_categories {
              ... on Strapi_ComponentSortableBlogCategory {
                blog_category {
                  data {
                    id
                    attributes {
                      title
                      backgroundColor
                      description
                      blog_articles(
                        filters: {
                          websites: { id: { eq: $websiteId } }
                          or: [
                            { endDate: null, startDate: null }
                            { endDate: { gte: $endDate }, startDate: null }
                            { endDate: null, startDate: { lte: $startDate } }
                            { endDate: { gte: $endDate }, startDate: { lte: $startDate } }
                          ]
                        }
                        pagination: { limit: 8 }
                        sort: ["startDate:DESC", "publishedAt:DESC"]
                      ) {
                        data {
                          id
                          attributes {
                            title
                            subTitle
                            hook
                            content
                            path
                            thumbnail_image {
                              data {
                                attributes {
                                  url
                                  mime
                                  image {
                                    childImageSharp {
                                      gatsbyImageData(placeholder: BLURRED, width: 830, height: 496)
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      backgroundImage {
                        data {
                          attributes {
                            url
                            mime
                            image {
                              childImageSharp {
                                gatsbyImageData(
                                  placeholder: BLURRED
                                  width: 1585
                                  height: 787
                                  formats: [AUTO, WEBP, AVIF]
                                )
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            pushTitle
            pushHook
            pushButton
            pushBackgroundImage {
              data {
                id
                attributes {
                  url
                  mime
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        width: 320
                        height: 660
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

Blog.pageType = 'blog'

export default Blog
